function loaderAnimation() {
    const tl = gsap.timeline();

    // Ensure SVG paths are prepared correctly
    const paths = document.querySelectorAll('.s-char-left, .s-char-right, .b-char-center, .oval-line-top, .oval-line-bottom');
    
    // Set strokeDasharray and strokeDashoffset for path animation
    paths.forEach(path => {
        const length = path.getTotalLength();
        
        // Set up initial properties for stroke animation
        gsap.set(path, {
            strokeDasharray: length,
            strokeDashoffset: length,
            stroke: "#0069AE", // Set stroke color
            fill: "none" // Set initial fill to none
        });
    });

    // Check if the 'initLoad' cookie exists
    const initLoadCookie = document.cookie.split(';').some(cookie => cookie.trim().startsWith('initLoad=true'));

    // If the logo has not yet been animated, we run the animation
    if (!initLoadCookie) {
        tl.to('.s-char-left, .s-char-right, .b-char-center', {
            strokeDashoffset: 0,
            duration: 1,
            ease: 'power2.out',
            stagger: 0.1,
        })
        .to('.s-char-left, .s-char-right, .b-char-center', {
            fill: "#0069AE",
            duration: .2,
            ease: 'power2.out',
            stagger: 0.1,
            immediateRender: false,
        });

        // Animate the dots in rows, sequentially
        const dotSets = [
            '.first-dot-left-bottom, .first-dot-left-top, .first-dot-center-bottom, .first-dot-center-top, .first-dot-right-bottom, .first-dot-right-top',
            '.second-dot-left-bottom, .second-dot-left-top, .second-dot-center-bottom, .second-dot-center-top, .second-dot-right-bottom, .second-dot-right-top',
            '.third-dot-left-bottom, .third-dot-left-top, .third-dot-center-bottom, .third-dot-center-top, .third-dot-right-bottom, .third-dot-right-top',
            '.fourth-dot-left-bottom, .fourth-dot-left-top, .fourth-dot-center-bottom, .fourth-dot-center-top, .fourth-dot-right-bottom, .fourth-dot-right-top'
        ];

        dotSets.forEach((dotSet, index) => {
            tl.to(dotSet, {
                opacity: 1,
                duration: 0.15,
                ease: 'power4.inOut',
            }, `+=${index === 0 ? 0 : 0.1}`);
        });

        // Animate the oval lines (top and bottom)
        tl.to('.oval-line-top, .oval-line-bottom', {
            strokeDashoffset: 0,
            duration: 1,
            ease: 'power4.inOut',
        })
        .to('.oval-line-top, .oval-line-bottom', {
            fill: "#0069AE",
            duration: .5,
            ease: 'power4.inOut',
        });

        // Animate the loader off-screen after the logo animation finishes
        tl.to('.loader', {
            yPercent: 100,
            duration: 0.7,
            ease: 'power4.inOut',
            transformOrigin: "bottom",
            onComplete: () => {
                gsap.set('.loader', { yPercent: -100 });
                sessionStorage.setItem("logoAnimated", "true");
                setCookie('initLoad', 'true', 1);
                document.body.style.overflow = 'auto';
            },
        });
    } else {
        // Skip logo animation and just animate the loader off-screen
        gsap.to('.loader', {
            yPercent: 100,
            duration: 0.7,
            ease: 'power4.inOut',
            transformOrigin: "bottom",
            onComplete: () => {
                gsap.set('.loader', { yPercent: -100 });
                document.body.style.overflow = 'auto'; // Restore scrolling
            },
        });
    }
}

// Handle internal link click (page transition)
function handleInternalLinkClick(event) {
    const loader = document.querySelector('.loader');
    const link = event.target.closest('a');

    if (
        link && (
            link.classList.contains('media-lightbox') ||
            link.hasAttribute('data-scroll') ||
            link.classList.contains('vacancy-list__card-btn') ||
            link.closest('.wpgb-pagination') ||
            (link.closest('.offcanvas-menu') && link.parentElement.classList.contains('menu-item-has-children'))
        )
    ) {
        return; // Do nothing for these specific cases
    }

    if (link && link.hostname === window.location.hostname) {
        event.preventDefault(); // Prevent default link behavior
        loader.style.transform = 'translateY(100%)'; // Show loader
        loader.style.opacity = 1; // Make the loader visible

        // Animate the loader in with GSAP
        gsap.to(loader, {
            yPercent: 0,
            opacity: 1,
            duration: 0.7,
            ease: 'power4.inOut',
            transformOrigin: 'top',
            onComplete: () => {
                // Navigate to the new page after the loader animation completes
                window.location.href = link.href;
            }
        });
    }
}

// Listen for internal link clicks
document.body.addEventListener('click', handleInternalLinkClick);

// Handle both onload and pageshow
window.addEventListener('load', loaderAnimation);
window.addEventListener('pageshow', (event) => {
    if (event.persisted) { // If the page was loaded from the cache
        loaderAnimation();
    }
});
